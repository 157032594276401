import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import WhatsappChat from "../pages/chat/WhatsappChat";
import Contacts from "../pages/contacts/Contacts";
import TemplatesList from "../pages/templates/TemplatesList";
import CreateTemplate from "../pages/templates/CreateTemplate";
import LoginPage from "../pages/Login/LoginPage";
import NotFound from "../pages/others/NotFound";
import BulkSendReport from "../pages/reports/bulkSend/BulkSendReport";
import BulkSendDetail from "../pages/reports/bulkSend/BulkSendDetail";

const Body = () => {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/chat",
      element: <WhatsappChat />,
    },
    {
      path: "/contacts",
      element: <Contacts />,
    },
    {
      path: "/templates",
      element: <TemplatesList />,
    },
    {
      path: "/create-template",
      element: <CreateTemplate />,
    },
    {
      path: "/bulk-send",
    },
    {
      path: "/report/bulk-send",
      element: <BulkSendReport />,
    },
    {
      path:'/bulk-send-view/:id',
      element:<BulkSendDetail/>
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <div>
      <RouterProvider router={appRouter} />
    </div>
  );
};

export default Body;
