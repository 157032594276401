import React, { useEffect } from "react";
import Login from "../../components/Login/Login";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem("login"));

    if (isLogin) {
      navigate("/dashboard");
    }
  }, []);
  return <Login />;
}

export default LoginPage;
