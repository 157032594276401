import { Avatar, Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import { sendMessageStatus } from "../../../constant/helperFunctions";
import moment from "moment";

const Message = styled(Box)({
   display: "flex",
   flexDirection: "column",
});

function InteractiveButtonMessage({ content }) {
   const { message_input } = content;
   const parcedMessage = JSON.parse(message_input);
   const { type } = parcedMessage?.interactive;
   const bodyText = parcedMessage?.interactive?.body?.text;
   const headerText = parcedMessage?.interactive?.header?.text;
   const footerText = parcedMessage?.interactive?.footer?.text;
   const actions = parcedMessage?.interactive?.action;
   const buttons = parcedMessage?.interactive?.action?.buttons;
   const reply= content?.message?.interactive?.button_reply
   const statusIcon = sendMessageStatus(content?.status);
   const time = moment(content.createdAt).format("h:mm a");


   console.log({ parcedMessage });
   return (
      <>
         {content?.flag === "incoming" && (
            <Box
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  maxWidth: content.flag === "incoming" ? "450px" : null,
                  justifyContent: content.flag === "outgoing" ? "end" : "start",
               }}
            >
               {content.flag === "incoming" && (
                  <Box p={1}>
                     <Avatar
                        sx={{
                           width: "30px",
                           height: "30px",
                           backgroundColor: "black",
                           color: "#fff",
                        }}
                     />
                  </Box>
               )}
               <Message>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor:
                           content.flag === "incoming" ? "#F4F4F6" : "#d9fdd3",
                        borderRadius:
                           content.flag === "incoming"
                              ? "0px 15px 15px 15px"
                              : "15px 15px 0px 15px",
                     }}
                  >
                     <Typography sx={{ fontSize: "13px" }}>
                        {reply?.title}
                     </Typography>
                     
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#978f8f",
                              textAlign:
                                 content.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {time}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
               </Message>
               {content.flag === "outgoing" && (
                  <Box p={1}>
                     <Avatar
                        sx={{
                           width: "30px",
                           height: "30px",
                           backgroundColor: "black",
                           color: "#fff",
                        }}
                     />
                  </Box>
               )}
            </Box>
         )}
         {content?.flag === "outgoing" && (
            <Box
               mt={1}
               sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
               }}
            >
               <Message>
                  <Box
                     p={1}
                     sx={{
                        width: "auto",
                        height: "auto",
                        backgroundColor: "#d9fdd3",
                        maxWidth: "300px",
                        //   content.flag === "incoming" ? "#F4F4F6" : "#2A8BC7",
                        borderRadius: "5px 5px 5px 5px",
                        //   content.flag === "incoming"
                        //     ? "0px 15px 15px 15px"
                        //     : "15px 15px 0px 15px",
                     }}
                  >
                     <Typography
                        variant="body1"
                        sx={{
                           fontSize: "14px",
                           color: "black",
                           fontWeight: "600",
                        }}
                     >
                        {headerText}
                     </Typography>
                     <span style={{ whiteSpace: "pre-line", fontSize: "13px" }}>
                        {bodyText}
                     </span>

                     {footerText && (
                        <Typography
                           variant="body1"
                           sx={{ fontSize: "11px", color: "grey" }}
                           color="initial"
                        >
                           {footerText}
                        </Typography>
                     )}
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "end",
                           alignItems: "center",
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: "11px",
                              fontWeight: "400",
                              color: "#978f8f",
                              textAlign:
                                 content?.flag === "incoming" ? "start" : "end",
                           }}
                        >
                           {/* {time} */}
                        </Typography>
                        {content.flag === "outgoing" && statusIcon}
                     </Box>
                  </Box>
                  {type === "cta_url" && (
                     <Box
                        mt={1 / 2}
                        display="flex"
                        justifyContent="space-between"
                     >
                        <Box
                           bgcolor="#fff"
                           borderRadius="5px"
                           width="100%"
                           textAlign="center"
                        >
                           <Button
                              variant="text"
                              color="primary"
                              sx={{ textTransform: "capitalize" }}
                           >
                              {actions?.parameters?.display_text}
                           </Button>
                        </Box>
                     </Box>
                  )}
                  {buttons?.map((btn) => (
                     <Box
                        mt={1 / 2}
                        display="flex"
                        justifyContent="space-between"
                     >
                        <Box
                           bgcolor="#fff"
                           borderRadius="5px"
                           width="100%"
                           textAlign="center"
                        >
                           <Button
                              variant="text"
                              color="primary"
                              sx={{ textTransform: "capitalize" }}
                           >
                              {btn?.reply?.title}
                           </Button>
                        </Box>
                     </Box>
                  ))}
               </Message>
               <Box p={1}>
                  <Avatar
                     sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "black",
                        color: "#fff",
                     }}
                  />
               </Box>
            </Box>
         )}
      </>
   );
}

export default InteractiveButtonMessage;
